@keyframes coinClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-coin-click {
  animation: coinClick 0.3s ease;
}

.bg-bgtetris {
  overflow-x: hidden;
}
.text-nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1e293b;
  padding: 2rem;
  border-radius: 0.5rem;
  outline: none;
  width: 80%; /* Sesuaikan lebar modal sesuai kebutuhan */
  max-width: 400px; /* Lebar maksimum modal */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-content img {
  width: 48px; /* Sesuaikan ukuran gambar sesuai kebutuhan */
  height: 48px; /* Sesuaikan ukuran gambar sesuai kebutuhan */
}

.modal-content .flex {
  flex-direction: row;
  align-items: center;
}

.modal-content .flex.flex-col {
  flex-direction: column;
}

.modal-content .text-white.text-sm {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



